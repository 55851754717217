import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'ZIKI',
    lpAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'ZIKI-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x0DB7AA74B3dD8cE1f082948cd847f59CDC8dB9f5',   // lp address token-bnb
    },
    tokenSymbol: 'ZIKI',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'PARA-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xd15181F8EF656f0e1BFA6D8f5c3dE2380f352a59',   // lp address token-bnb
    },
    tokenSymbol: 'ZIKI',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  
  {
    pid: 3,
    lpSymbol: 'PARA-ETH LP',
    lpAddresses: {
      97: '',
      56: '0xF744eA7c215eFF7CC89A99C636be594454f92887',   // lp address token-bnb
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x8a5652eb940dd3832a8426fbe5afbb01b0f96a14', // token address
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.eth,
  },
  
  {
    pid: 4,
    lpSymbol: 'PARA-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x18538ae8fb405f58326c46f61f6fbae112a6ca17',   // lp address token-bnb
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x8a5652eb940dd3832a8426fbe5afbb01b0f96a14', // token address
    },
    quoteTokenSymbol: QuoteToken.UST,
    quoteTokenAdresses: contracts.ust,
  },
  {
    pid: 5,
    lpSymbol: 'ZIKI-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xa89de500C74B1A231c552D390cCf228012023458',   // lp address token-bnb
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.UST,
    quoteTokenAdresses: contracts.ust,
  },
  {
    pid: 6,
    lpSymbol: 'PARA-BTC LP',
    lpAddresses: {
      97: '',
      56: '0xcd74EF7B95A6046cE4E071F89051bF7138DCff3d',   // lp address token-bnb
    },
    tokenSymbol: 'BTC',
    tokenAddresses: {
      97: '',
      56: '0x8a5652eb940dd3832a8426fbe5afbb01b0f96a14', // token address
    },
    quoteTokenSymbol: QuoteToken.BTC,
    quoteTokenAdresses: contracts.btc,
  },
  {
    pid: 7,
    lpSymbol: 'PARA-ZIKI LP',
    lpAddresses: {
      97: '',
      56: '0x1156D05223819cC1da6aB016875b1F163bF5a7D1',   // lp address token-bnb
    },
    tokenSymbol: 'ZIKI',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 8,
    lpSymbol: 'BNB-ETH LP',
    lpAddresses: {
      97: '',
      56: '0x74e4716e431f45807dcf19f284c7aa99f18a4fbc',   // lp address token-bnb
    },
    tokenSymbol: 'ZIKI',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 9,
    lpSymbol: 'BTCB-ZIKI LP',
    lpAddresses: {
      97: '',
      56: '0x4F6cD0aD92134E5F36e8803D749edD57f161BDEC',   // lp address token-bnb
    },
    tokenSymbol: 'ZIKI',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.BTC,
    quoteTokenAdresses: contracts.btc,
  },
  {
    pid: 10,
    lpSymbol: 'PARA-EUROS LP',
    lpAddresses: {
      97: '',
      56: '0x5a76Fb1bcD38bBA00109a25A082ceE4B77AcF2d1',   // lp address token-bnb
    },
    tokenSymbol: 'ZIKI',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.UST,
    quoteTokenAdresses: contracts.ust,
  },
  {
    pid: 11,
    lpSymbol: 'WBNB-EUROS LP',
    lpAddresses: {
      97: '',
      56: '0xCC749bE15Fc7606963626b557d2ad6f412f66f61',   // lp address token-bnb
    },
    tokenSymbol: 'ZIKI',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 12,
    lpSymbol: 'ZIKI-EUROS LP',
    lpAddresses: {
      97: '',
      56: '0xF58c397bb8a11DEBbaD3f0a00a4a070c8B07082E',   // lp address token-bnb
    },
    tokenSymbol: 'ZIKI',
    tokenAddresses: {
      97: '',
      56: '0x7C2c5A42EB7cf814DF85e7Ad0Dae4511913b6272', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
]

export default farms
